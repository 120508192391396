var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c(
      "div",
      { staticClass: "min-h-100vh h-full bg-[#f8f8f8]" },
      [
        _c("my-nav", {
          attrs: { title: "任务抽查结果详情", "self-back": true },
          on: { back: _vm.goBack },
        }),
        _vm.vanListShow
          ? _c(
              "van-list",
              {
                staticClass: "p-8px details_main space-y-16px",
                attrs: {
                  finished: _vm.finished,
                  "finished-text": "没有更多啦",
                  "immediate-check": false,
                },
                on: { load: _vm.load_more },
                model: {
                  value: _vm.pageLoading,
                  callback: function ($$v) {
                    _vm.pageLoading = $$v
                  },
                  expression: "pageLoading",
                },
              },
              _vm._l(_vm.pageData, function (item, index) {
                return _c(
                  "van-collapse",
                  {
                    key: index,
                    model: {
                      value: item.isFold,
                      callback: function ($$v) {
                        _vm.$set(item, "isFold", $$v)
                      },
                      expression: "item.isFold",
                    },
                  },
                  [
                    _c(
                      "section",
                      {
                        staticClass:
                          "bg-white rounded-8px pt-12px pb-8px px-8px",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "text-18px font-medium text-[#222] leading-26px",
                          },
                          [_vm._v(_vm._s(item.standardName))]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "space-y-6px mt-6px cus_border pb-10px",
                          },
                          [
                            _c("div", { staticClass: "top_row_details" }, [
                              _c("span", [_vm._v("任务属性:")]),
                              _c("span", [_vm._v(_vm._s(item.attributeName))]),
                            ]),
                            _c("div", { staticClass: "top_row_details" }, [
                              _c("span", [_vm._v("任务分类:")]),
                              _c("span", [_vm._v(_vm._s(item.classifyName))]),
                            ]),
                            _c("div", { staticClass: "top_row_details" }, [
                              _c("span", [_vm._v("所在项目:")]),
                              _c("span", [_vm._v(_vm._s(item.projectName))]),
                            ]),
                          ]
                        ),
                        _c("van-collapse-item", {
                          attrs: { name: index },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "collapse_title flex items-center",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "vertical_center" },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-[#8B8F9A]" },
                                              [_vm._v("抽查数量:")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "collapse_title_num bg-[#3366FE]",
                                              },
                                              [_vm._v(_vm._s(item.taskNum))]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "vertical_center ml-12px",
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-[#8B8F9A]" },
                                              [_vm._v("抽查结果:")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "collapse_title_num bg-[#28C469]",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.qualifiedNum)
                                                ),
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass: "custom_divider",
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "collapse_title_num bg-[#F44A45]",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.unQqualifiedNum)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "space-y-8px mt-12px" },
                                      _vm._l(
                                        item.orders,
                                        function (subitem, subidx) {
                                          return _c(
                                            "section",
                                            {
                                              key: subidx,
                                              staticClass:
                                                "text-[#222] bg-[#F2F2F2] rounded-6px pt-16px pb-10px px-10px text-16px relative",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.goDetails(subitem)
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "absolute right-10px top-14px z-10",
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: _vm.calcImg(subitem),
                                                      alt: "",
                                                      width: "62px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "row_code mb-5px",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "text-14px",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subitem.orderNo)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "inline-flex copy_border text-13px",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          $event.stopPropagation()
                                                          return _vm.copyOrderNumber(
                                                            subitem.orderNo
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("复制")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row_details" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "w-70px" },
                                                    [_vm._v("处理人:")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "calc_person",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          subitem.handlerName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row_details" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "w-115px" },
                                                    [_vm._v("计划开始时间:")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "calc_time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          subitem.planStart
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "row_details" },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "w-115px" },
                                                    [_vm._v("计划结束时间:")]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "calc_time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subitem.planEnd)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              subitem.taskAddr
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "row_details",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "w-85px",
                                                        },
                                                        [_vm._v("任务位置:")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "calc_position",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              subitem.taskAddr
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }