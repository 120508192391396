<template>
  <div class="">
    <div class="min-h-100vh h-full bg-[#f8f8f8]">
      <my-nav title='任务抽查结果详情' :self-back="true" @back="goBack"></my-nav>
      <van-list class="p-8px details_main space-y-16px" v-model="pageLoading" :finished="finished"
        finished-text="没有更多啦" @load="load_more" :immediate-check="false" v-if="vanListShow">
        <!-- 每一条记录 -->
        <van-collapse v-for="(item, index) in pageData" :key="index" v-model="item.isFold">
          <section class="bg-white rounded-8px pt-12px pb-8px px-8px">
            <div class="text-18px font-medium text-[#222] leading-26px">{{item.standardName}}</div>
            <div class="space-y-6px mt-6px cus_border pb-10px">
              <div class="top_row_details">
                <span>任务属性:</span>
                <span>{{item.attributeName}}</span>
              </div>
              <div class="top_row_details">
                <span>任务分类:</span>
                <span>{{item.classifyName}}</span>
              </div>
              <div class=" top_row_details">
                <span>所在项目:</span>
                <span>{{item.projectName}}</span>
              </div>
            </div>
            <van-collapse-item :name="index">
              <template #title>
                <div class="collapse_title flex items-center">
                  <div class="vertical_center">
                    <span class="text-[#8B8F9A]">抽查数量:</span>
                    <span class="collapse_title_num bg-[#3366FE]">{{item.taskNum}}</span>
                  </div>
                  <div class="vertical_center ml-12px">
                    <span class="text-[#8B8F9A]">抽查结果:</span>
                    <span class="collapse_title_num bg-[#28C469]">{{item.qualifiedNum}}</span>
                    <div class="custom_divider"></div>
                    <span class="collapse_title_num bg-[#F44A45]">{{item.unQqualifiedNum}}</span>
                  </div>
                </div>
              </template>
              <template #default>
                <div class="space-y-8px mt-12px">
                  <!-- 每一项展开后的灰色块 -->
                  <section v-for="(subitem, subidx) in item.orders" :key="subidx"
                    @click="goDetails(subitem)"
                    class="text-[#222] bg-[#F2F2F2] rounded-6px pt-16px pb-10px px-10px text-16px relative">
                    <div class="absolute right-10px top-14px z-10">
                      <img :src="calcImg(subitem)" alt="" width="62px">
                    </div>
                    <div class="row_code mb-5px">
                      <span class="text-14px">{{subitem.orderNo}}</span>
                      <span class="inline-flex copy_border text-13px"
                        @click.prevent.stop="copyOrderNumber(subitem.orderNo)">复制</span>
                    </div>
                    <div class="row_details">
                      <span class="w-70px">处理人:</span>
                      <span class="calc_person">{{subitem.handlerName}}</span>
                    </div>
                    <div class="row_details">
                      <span class="w-115px">计划开始时间:</span>
                      <span class="calc_time">{{subitem.planStart}}</span>
                    </div>
                    <div class="row_details">
                      <span class="w-115px">计划结束时间:</span>
                      <span class="calc_time">{{subitem.planEnd}}</span>
                    </div>
                    <div class="row_details" v-if="subitem.taskAddr">
                      <span class="w-85px">任务位置:</span>
                      <span class="calc_position">{{subitem.taskAddr}}</span>
                    </div>
                  </section>
                </div>
              </template>
            </van-collapse-item>
          </section>
        </van-collapse>
      </van-list>
    </div>
  </div>
</template>

<script>
import api from '@/api/index'
import mobile from '@/utils/mobile'
export default {
  data () {
    return {
      pageLoading: false, // 加载状态
      finished: false, // 是否加载
      search: {
        page: 1,
        limit: 10,
        checkNo: undefined
      },
      pageData: [],
      vanListShow: false
    }
  },
  computed: {
    pageType () {
      return this.$store.getters.getPageType
    }
  },
  mounted () {
    this.search.checkNo = this.$route.query.checkNo
    this.getData(true)
  },
  methods: {
    async changeStatus () { // 修改为已读
      const obj = {
        msgId: this.$route.query.msgId,
        status: 3
      }
      await this.$http.post(api.setReadReq, obj, false)
    },
    load_more () {
      this.search.page += 1// 页数+1
      this.getData()
    },
    copyOrderNumber (orderNo) { // 复制
      this.$copyText(orderNo).then(
        (e) => {
          this.$notify({
            type: 'success',
            message: '复制成功',
            background: '#13CE66'
          })
        },
        (e) => {
          this.$notify({
            type: 'warning',
            message: '复制成功',
            background: '#FFC82C'
          })
        }
      )
    },
    calcImg (item) { // 返回合格印章或不合格印章
      return item.qualified
        ? require('@/asset/qualifi.png')
        : require('@/asset/unqualifi.png')
    },
    goDetails (item) {
      this.$router.push({
        path: '/OrderDetail',
        query: {
          orderId: item.id,
          checkNo: this.$route.query.checkNo
        }
      })
    },
    async getData (loading = false) { // 获取数据
      if (loading) {
        this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
          duration: 999999
        })
      }
      const data = await this.$http.post(api.getCheckDetailsReq, this.search, false)
      this.vanListShow = true
      this.$toast.clear()
      this.pageLoading = false
      ;(data.records || []).forEach(i => { i.isFold = [] })
      this.pageData = this.pageData.concat(data.records)
      if (this.pageData?.length >= data.total) {
        this.finished = true
      }
    },
    goBack () {
      // 如果是推送到的此页面，就直接返回APP九宫格
      if (this.$store.getters.getRouter.length === 1) {
        mobile.backWeb()
      } else {
        // 否则直接返回上一级
        this.$router.back()
      }
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>
